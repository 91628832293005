/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesMathKangaroo = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Math Kangaroo Past Papers</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <h3>Previous year questions (PYQs)</h3>
                    <table>
                    <tr>
                            <td><br></br><strong>Year</strong></td>
                            <td><strong>Level 1</strong> (Grades 1-2)</td>
                            <td><strong>Level 2</strong> (Grades 3-4)</td>
                            <td><strong>Level 3</strong> (Grades 5-6)</td>
                            <td><strong>Level 4</strong> (Grades 7-8)</td>
                            <td><strong>Level 5</strong> (Grades 9-10)</td>
                            <td><strong>Level 6</strong> (Grades 11-12)</td>
                        </tr>
                        {/* <tr>
                            <td><strong>2024</strong></td>
                            <td>
                                <Link to='/resources/math-kangaroo/2024-l1'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2024_L1.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2024-l2'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2024_L2.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2024-l3'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2024_L3.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2024-l4'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2024_L4.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2024-l5'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2024_L5.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2024-l6'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2024_L6.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                        </tr> */}
                        <tr>
                            <td><strong>2023</strong></td>
                            <td>
                                <Link to='/resources/math-kangaroo/2023-l1'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/yrC5-A-wC5I' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2023_L1.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2023-l2'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/LWYyEBOELD8' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2023_L2.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2023-l3'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2023_L3.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2023-l4'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2023_L4.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2023-l5'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2023_L5.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2023-l6'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2023_L6.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                        </tr>
                        <tr>
                            <td><strong>2022</strong></td>
                            <td>
                                <Link to='/resources/math-kangaroo/2022-l1'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/YDU0U1Xv-ng' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2022_L1.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2022-l2'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/5pG8NDer26Y' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2022_L2.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2022-l3'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/b6Ih7mUmq_M' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2022_L3.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2022-l4'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/UESEiSQ17nU' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2022_L4.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2022-l5'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/dmjRYVjXwj4' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2022_L5.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2022-l6'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/x125NxCX3xw' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2022_L6.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                        </tr>
                        <tr>
                            <td><strong>2021</strong></td>
                            <td>
                                <Link to='/resources/math-kangaroo/2021-l1'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/_FfxJhtc2S0' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2021_L1.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2021-l2'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/hZgr0gadG5s' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2021_L2.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2021-l3'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/P-2PMgdm9Hc' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2021_L3.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2021-l4'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/BrksbzMZGXc' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2021_L4.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2021-l5'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/LdkHa3vlmBQ' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2021_L5.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2021-l6'>Questions</Link>
                                <br></br>[ <a href='https://youtu.be/3Pw_WJfZZ8k' target='_blank' rel='noreferrer'>video</a> | <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2021_L6.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                        </tr>
                        <tr>
                            <td><strong>2020</strong></td>
                            <td>
                                <Link to='/resources/math-kangaroo/2020-l1'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2020_L1.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2020-l2'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2020_L2.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2020-l3'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2020_L3.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2020-l4'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2020_L4.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2020-l5'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2020_L5.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                            <td>
                                <Link to='/resources/math-kangaroo/2020-l6'>Questions</Link>
                                <br></br>[ <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MK2020_L6.pdf?raw=true" target="_blank">pdf</a> ]
                            </td>
                        </tr>
                    </table>
                    <p>Answer keys (all years and levels): <a href="https://github.com/boo9pub/docs/blob/main/math-kangaroo/PYQs/MKAnsKeys.pdf?raw=true" target="_blank">download</a></p>
                    <p>Would you like to see more past papers? <Link to='/contact'>Let us know.</Link></p>

                    <br></br>
                    <h3>How to practice</h3>
                    <p>The absolute best way to prepare for a test is by practicing previous year questions (PYQs).
                        Keeping that in mind, we provide several options for how you can practice the PYQs:</p>
                    <ol>
                        <li>
                            The best option is to use our <Link to='/practice'>Practice</Link> facility <span style={{ fontSize: '42px' }}>&#128522;</span>. Some benefits include
                            <ul>
                                <li>Weekly practice, timed practice, with automated grading & progress tracking (FREE)</li>
                                <li>On-demand and adaptive practice (for a nominal fee, see <Link to='/pricing'>pricing</Link>)</li>
                                <li>Video solution links for individual questions (many are already available, more coming soon)</li>
                                <li>Stay tuned for more <span style={{ fontSize: '42px' }}>&#128540;</span> </li>
                            </ul>
                        </li>
                        <li>OR, Click on a <strong>Questions</strong> link above. It includes answer keys, and video explanations if available.</li>
                        <li>OR, Click on a <strong>video</strong> link above. Pause the video and try solving it yourself first.</li>
                        <li>OR, Download a <strong>pdf</strong>. Look for the answer keys in a separate pdf.</li>
                    </ol>
                    <p>If there is anything we can do to make this more helpful, or if you have any feedback, please <Link to='/contact'>contact us.</Link></p>
                    <p>Thank you for stopping by <span style={{ fontSize: '42px' }}>&#x1F64F;</span>. We wish you success!</p>
                </Container>
            </div>
		</div>
	);
};

export default ResourcesMathKangaroo;
