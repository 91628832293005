/* eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import { ReactSVG } from "react-svg";
import { Button, Form, Container, Image } from "react-bootstrap";
import {
  getUserProfile,
  updateUser,
  currentSubscriptionPlane,
  paymentSessionCreate,
  stopAutoRecurring,
  updateNotificationValues,
} from "../../redux/actions";
import { reset } from "../../redux/slices/userProfileSlice";
import editIcon from "../../assets/images/icons/edit-icon-white.svg";
import "./styles.scss";
import CustomModal from "../../components/CustomModal";

const SettingsPage = () => {
  const [fieldValue, setFieldValue] = useState([true, true, true]);
  const dispatch = useDispatch();
  const shouldrender = useRef(true);
  const {
    loading,
    userData,
    updateUserData,
    currentSubscriptionData,
    getUserProfileError,
    updateError,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    if (shouldrender.current) {
      shouldrender.current = false;
      dispatch(getUserProfile());
    }
  }, [updateUserData]);

  useEffect(() => {
    if (userData && userData.user_data && userData.user_data.updates) {
      const updates = userData.user_data.updates;
      setFieldValue([
        updates.important_updates,
        updates.product_updates,
        updates.general_communications,
      ]);
    }
  }, [userData]);

  const handleChange = (e, index) => {
    const { checked } = e.target;
    setFieldValue((prev) => {
      const newFieldValue = [...prev];
      newFieldValue[index] = checked;
      const updates = {
        important_updates: newFieldValue[0],
        product_updates: newFieldValue[1],
        general_communications: newFieldValue[2],
      };
      dispatch(
        updateNotificationValues({
          username: userData?.user_data?.username,
          email: userData?.user_data?.email,
          _id: userData?.user_data?._id,
          updates: updates,
        })
      );
      return newFieldValue;
    });
  };

  const items = [
    "Important updates",
    "Product updates",
    "General communications",
  ];
  return (
    <div className="settings_section">
      <section className="security_section">
        <section class="inner_banner_section setting">
          <div class="container">
            <h1>Settings</h1>
          </div>
        </section>
        <Container>
          <div className="security_wrapper mt-5 top-setting">
            <h2>Security</h2>
            <div>
              <Link to="/change-password" className="change-password my-5">
                Change my password
              </Link>
              {userData?.last_login ? (
                <p>
                  Last Login :{" "}
                  {moment(
                    userData?.last_login?.login_time,
                    "ddd, DD MMM YYYY HH:mm:ss z"
                  ).format("dddd MMM DD, YYYY")}{" "}
                  from {userData?.last_login?.city},{" "}
                  {userData?.last_login?.country}
                </p>
              ) : (
                <p>Last Login : </p>
              )}
            </div>
          </div>
        </Container>
      </section>

      <section className="security_section mt-2">
        <Container>
          <hr class="hr-clr"></hr>
          <div className="security_wrapper devide-sec mb-5">
            <h2>Email subscription</h2>
            <div>
              <p className="receive-email mb-1">Receive email about:</p>
              <Form>
                {items.map((item, index) => (
                  <Form.Check key={index}>
                    <div className="mb-1">
                      <Form.Check.Input
                        type="checkbox"
                        id={`${index}`}
                        name={`${index}`}
                        onChange={(e) => handleChange(e, index)}
                        checked={fieldValue[index]}
                        value={index}
                        disabled={index === 0}
                      />
                      <Form.Check.Label className="notify-items">
                        {item}
                      </Form.Check.Label>
                      <br />
                    </div>
                  </Form.Check>
                ))}
              </Form>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SettingsPage;
